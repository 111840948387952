<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <div>
        <van-search
          v-model="search"
          show-action
          label="搜索"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad"
      >
        <div class="center" v-for="item in list" :key="item.index">
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="shopTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content" style="padding: 8px 10px">
            <div style="display: inline-block" class="ContentA">
              <van-image
                style="width: 100%; height: 85px"
                :src="item.ShopImgUrl"
              >
                <template style="width: 100%; height: 85px" v-slot:error>
                  <img
                    src="https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png"
                    alt=""
                  />
                </template>
              </van-image>
            </div>
            <div style="display: inline-block" class="ContentB">
              <span style="color: #333" class="van-multi-ellipsis--l3"
                ><van-icon name="location" />{{ item.Address }}</span
              >
              <br />
              <span style="font-size: 12px; color: #333"
                >{{ item.IName }}</span
              >
              <br />
              <span class="centertime" style="color: #333">
                <van-icon name="manager" />{{ item.Charger }}
                <van-icon name="phone" />{{ item.Mobile }}
              </span>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WeGetShopPage } from "@/api/RealInfo";
export default {
  data() {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      this.listfrom.riKind = this.riKind;
      this.listfrom.title = this.search;
      WeGetShopPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ShopImgUrl != "rejected") {
                  this.list[i].ShopImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>